
 
 import axios from '../../utility/axios';
 import { showServerErrors } from '../../utility/utility';

const actions = {
    FETCH_POSTS_BEGIN: 'FETCH_POSTS_BEGIN',
    FETCH_POSTS_SUCCESS: 'FETCH_POSTS_SUCCESS',
    FETCH_POSTS_ERROR: 'FETCH_POSTS_ERROR',

    PUBLISH_POST_BEGIN: 'PUBLISH_POST_BEGIN',
    PUBLISH_POST_SUCCESS: 'PUBLISH_POST_SUCCESS',
    PUBLISH_POST_ERROR: 'PUBLISH_POST_ERROR',

    fetchPostsBegin: () => {
      return {
        type: 'FETCH_POSTS_BEGIN',
      };
    },
  
    fetchPostsSuccess: data => {
      return {
        type: 'FETCH_POSTS_SUCCESS',
        data,
      };
    },
  
    fetchPostsError: err => {
      return {
        type: 'FETCH_POSTS_ERROR',
        err,
      };
    },

    publishPostBegin: () => {
      return {
        type: 'PUBLISH_POST_BEGIN'
      }
    },

    publishPostSuccess: () => {
      return {
        type: 'PUBLISH_POST_SUCCESS',
      }
    },
    publishPostError: err => {
      return {
        type: 'PUBLISH_POST_ERROR',
        err
      };
    },
  };


  const { fetchPostsBegin, fetchPostsSuccess, fetchPostsError, publishPostBegin, publishPostSuccess, publishPostError } = actions;


  const fetchPosts = (target, page) => {
    return async dispatch => {
      try {
        dispatch(fetchPostsBegin());
        const uri = `/api/newsfeed?target=${target}&pageNumber=${page}`
        const response = await axios.get(uri);

        return dispatch(fetchPostsSuccess(response));
      } catch (err) {
        showServerErrors(err);
        dispatch(fetchPostsError(err));
      }
    };
  };
 

const initState = {
    loading: false,
    error: null,
    data: {
      items: [],
      pageIndex: 0,
      totalPages: 0,
      totalCount: 0,
      hasPreviousPage: false,
      hasNextPage: false,
    },
  
  };


  const newsfeedReducer = (state = initState, action) => {
    const { type, data, err } = action;
    switch (type) {
      case actions.FETCH_POSTS_BEGIN:
        return {
          ...state,
          loading: true,
        };

      case actions.FETCH_POSTS_SUCCESS:
        return {
          ...state,
          data,
          loading: false,
        };

      case actions.FETCH_POSTS_ERROR:
        return {
          ...state,
          error: err,
          loading: false,
        };
  
      default:
        return state;
    }
  };


  export { newsfeedReducer, fetchPosts };