import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const ContentPage = lazy(() => import('../../pages/contentpage/ContentPage'));

const ContentPageRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`/`} component={ContentPage} />
    </Switch>
  );
};

export default ContentPageRoutes;
