import actions from './actions';

const {
  GET_TENANTS_BEGIN,
  GET_TENANTS_SUCCESS,
  GET_TENANTS_ERROR,
  GET_PROFILES_WITH_STREETS_BEGIN,
  GET_PROFILES_WITH_STREETS_SUCCESS,
  GET_PROFILES_WITH_STREETS_ERROR,
  UPDATE_TENANT_STATUS,
} = actions;

const initState = {
  loading: false,
  error: null,
  data: {
    items: [],
    pageIndex: 0,
    totalPages: 0,
    totalCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  },
  profilesWithStreets: [],
};

const TenantsReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_TENANTS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_TENANTS_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_TENANTS_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case GET_PROFILES_WITH_STREETS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILES_WITH_STREETS_SUCCESS:
      return {
        ...state,
        profilesWithStreets: data,
        loading: false,
      };
    case GET_PROFILES_WITH_STREETS_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case UPDATE_TENANT_STATUS:
      debugger;
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data?.items?.map(user =>
            user.id === data.id
              ? Object.assign({}, user, {
                  isApproved: data.isApproved,
                  totalApproved: user.totalApproved + data.totalApproved,
                  approvalInformation: `${user.totalApproved + data.totalApproved}/${user.totalUnits} units approved`,
                })
              : user,
          ),
        },
      };
    default:
      return state;
  }
};

export { TenantsReducer };
