import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Businesses = lazy(() => import('../../pages/businesses/Businesses'));

const BusinessesRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/list`} component={Businesses} />
    </Switch>
  );
};

export default BusinessesRoutes;
