const actions = {
  GET_TENANTS_BEGIN: 'GET_TENANTS_BEGIN',
  GET_TENANTS_SUCCESS: 'GET_TENANTS_SUCCESS',
  GET_TENANTS_ERROR: 'GET_TENANTS_ERROR',

  GET_PROFILES_WITH_STREETS_BEGIN: 'GET_PROFILES_WITH_STREETS_BEGIN',
  GET_PROFILES_WITH_STREETS_SUCCESS: 'GET_PROFILES_WITH_STREETS_SUCCESS',
  GET_PROFILES_WITH_STREETS_ERROR: 'GET_PROFILES_WITH_STREETS_ERROR',

  UPDATE_TENANT_STATUS: 'UPDATE_TENANT_STATUS',

  tenantsBegin: () => {
    return {
      type: actions.GET_TENANTS_BEGIN,
    };
  },

  tenantsSuccess: data => {
    return {
      type: actions.GET_TENANTS_SUCCESS,
      data,
    };
  },

  tenantsError: err => {
    return {
      type: actions.GET_TENANTS_ERROR,
      err,
    };
  },

  profilesWithStreetsBegin: () => {
    return {
      type: actions.GET_PROFILES_WITH_STREETS_BEGIN,
    };
  },

  profilesWithStreetsSuccess: data => {
    return {
      type: actions.GET_PROFILES_WITH_STREETS_SUCCESS,
      data,
    };
  },

  profilesWithStreetsError: err => {
    return {
      type: actions.GET_PROFILES_WITH_STREETS_ERROR,
      err,
    };
  },

  updateTenatStatus: data => {
    return {
      type: actions.UPDATE_TENANT_STATUS,
      data,
    };
  },
};

export default actions;
