import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import AuthorizeRoute from '../../components/api-authorization/AuthorizeRoute';

const NewsfeedPage = lazy(() => import('../../pages/newsfeed/NewsfeedPage'));

const NewsfeedRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <AuthorizeRoute path="/" component={NewsfeedPage} />
    </Switch>
  );
};

export default NewsfeedRoutes;
