let axios = require('axios');
let axiosApiInstance = axios.create();
import authService from '../components/api-authorization/AuthorizeService';
console.log(axiosApiInstance.defaults.baseURL)
// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async config => {
    const token = await authService.getAccessToken();
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'X-Requested-With': 'XHMLHttpRequest',
    };
    return config;
  },
  error => {
    if (error.response && error.response.status === 400) {
      const serverError = error.response.data;
      let errorMessages = '';
      Object.keys(serverError.errors).forEach(key => {
        errorMessages += serverError.errors[key].join('\n');
      });
      openNotificationWithIcon('error', error.title, errorMessages);
    } else if (error.response && error.response.status === 401) {
      // Unauthorized Request
      history.push('/authentication/login');
    } else if (error.response && error.response.status === 403) {
      // Forbiden Request
      // store.dispatch(forbidenRequest());
      history.push('/error/403');
    } else if (error.response && error.response.status === 404) {
      // route not found
      // store.dispatch(erroredRequest());
      history.push('/error/404');
    }
    Promise.reject(error.response.data);
  },
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  async response => response.data,
  async function(error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      // const access_token = await refreshAccessToken();
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      // return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default axiosApiInstance;
