import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const ContactNumbers = lazy(() => import('../../pages/contact-numbers/ContactNumbers'));

const ContactNumberRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`} component={ContactNumbers} />
    </Switch>
  );
};

export default ContactNumberRoutes;
