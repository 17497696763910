import openNotificationWithIcon from './notification';

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};

const textRefactor = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};

const showServerErrors = error => {
  const _error = { ...error };

  if (_error.response && (_error.response.status === 400 || _error.response.status === 500 || _error.response.status === 415)) {
    const serverError = _error.response.data;
    let errorMessages = '';
    if (serverError.errors) {
      Object.keys(serverError.errors).forEach(key => {
        errorMessages += serverError.errors[key].join('\n');
      });
    } else if (serverError.detail) {
      errorMessages += serverError.detail;
    }

    openNotificationWithIcon('error', error.title, errorMessages);
  } else if (_error.response && _error.response.status === 401) {
    // Unauthorized Request
    windows.location.replace('/authentication/login');
  } else if (_error.response && _error.response.status === 403) {
    // Forbiden Request
    // store.dispatch(forbidenRequest());
    windows.location.replace('/error/403');
  } else if (_error.response && _error.response.status === 404) {
    // route not found
    // store.dispatch(erroredRequest());
    windows.location.replace('/error/404');
  }
};


const isVideo = url => url && (url.endsWith('.mp4') || url.endsWith('.mov') || url.endsWith('.avchd') || url.endsWith('.avi'));

const convertToMegaByte = (bytes )=> (bytes / (1024*1024)).toFixed(2);


export { ellipsis, textRefactor, showServerErrors ,isVideo, convertToMegaByte};
