import React from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  return (
    <Menu
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={['newsfeed']}
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'newsfeed'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
    >
      <Menu.Item key="newsfeed" icon={!topMenu && <FeatherIcon icon="layout" />} title="Newsfeed">
        <NavLink className="active" onClick={toggleCollapsed} to="/newsfeed">
          Newsfeed
        </NavLink>
      </Menu.Item>
      <Menu.Item key="analytics" icon={!topMenu && <FeatherIcon icon="bar-chart-2" />} title="Analytics">
        <NavLink onClick={toggleCollapsed} to="/analytics">
          Analytics
        </NavLink>
      </Menu.Item>
      <SubMenu title="Tenants" icon={!topMenu && <FeatherIcon icon="users" />}>
        <Menu.Item key="approved" title="Approved" title="approved">
          <NavLink onClick={toggleCollapsed} to="/tenants?state=1">
            {!topMenu && <FeatherIcon icon="user-check" style={{ marginRight: '15px' }} />} Approved
          </NavLink>
        </Menu.Item>
        <Menu.Item key="non-approved" title="Non Approved" title="non-approved">
          <NavLink onClick={toggleCollapsed} to="/tenants?state=0">
            {!topMenu && <FeatherIcon icon="user-x" style={{ marginRight: '15px' }} />} Non Approved
          </NavLink>
        </Menu.Item>
      </SubMenu>

      <SubMenu title="Administration" icon={!topMenu && <FeatherIcon icon="command" />}>
        <Menu.Item key="profiles" title="Profiles">
          <NavLink onClick={toggleCollapsed} to="/profiles">
            {!topMenu && <FeatherIcon icon="star" style={{ marginRight: '15px' }} />} <></>
            Profiles
          </NavLink>
        </Menu.Item>
        <Menu.Item key="users" title="Team">
          <NavLink onClick={toggleCollapsed} to="/users">
            {!topMenu && <FeatherIcon icon="heart" style={{ marginRight: '15px' }} />} <></>
            Our Team
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu title="CMS" icon={!topMenu && <FeatherIcon icon="file-text" />}>
        <Menu.Item key="contentpage" title="Content Pages">
          <NavLink onClick={toggleCollapsed} to="/contentpage">
            {!topMenu && <FeatherIcon icon="server" style={{ marginRight: '15px' }} />}
            Content Pages
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu title="Configurations" icon={!topMenu && <FeatherIcon icon="settings" />}>
        <Menu.Item key="businesses" title="Businesses">
          <NavLink onClick={toggleCollapsed} to="/businesses/list">
            {!topMenu && <FeatherIcon icon="briefcase" style={{ marginRight: '15px' }} />}
            Businesses
          </NavLink>
        </Menu.Item>
        <Menu.Item key="streets" title="Streets">
          <NavLink onClick={toggleCollapsed} to="/streets">
            {!topMenu && <FeatherIcon icon="minimize" style={{ marginRight: '15px' }} />}
            Streets
          </NavLink>
        </Menu.Item>
        <Menu.Item key="contact-numbers" title="contact-numbers">
          <NavLink onClick={toggleCollapsed} to="/contact-numbers">
            {!topMenu && <FeatherIcon icon="phone" style={{ marginRight: '15px' }} />}
            Contacts
          </NavLink>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
