import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Tenants from './tenants';
import Users from './users';
import Newsfeed from './newsfeed';
import ContentPage from './contentpage'
import Businesses from './businesses';
import AnalyticsPage from './analytics';
import withAdminLayout from '../../layout/withAdminLayout';
import Profiles from './profiles';
import AuthorizeRoute from '../../components/api-authorization/AuthorizeRoute';
import Streets from './streets';
import ContactNumbers from './contact-numbers';


const Admin = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <AuthorizeRoute path="/users" component={Users} />
        <AuthorizeRoute path="/tenants" component={Tenants} />
        <AuthorizeRoute path="/analytics" component={AnalyticsPage} />
        <AuthorizeRoute path="/newsfeed" component={Newsfeed} />
        <AuthorizeRoute path="/businesses" component={Businesses} />
        <AuthorizeRoute path="/profiles" component={Profiles} />
        <AuthorizeRoute path="/streets" component={Streets} />
        <AuthorizeRoute path="/contact-numbers" component={ContactNumbers} />
        <AuthorizeRoute path="/contentpage" component={ContentPage} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
