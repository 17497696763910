import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const AnalyticsPage = lazy(() => import('../../pages/analytics/AnalyticsPage'));

const AnalyticsRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`} component={AnalyticsPage} />
    </Switch>
  );
};

export default AnalyticsRoute;
